import React, {Component} from 'react';

import {
	Container,
	Form,
	Button,
	Input,
	FormGroup,
	FormFeedback,
	Label,
} from 'reactstrap';

import {
	login,
} from './api-calls';

export default class Login extends Component {

	constructor(props){
		super(props);
		this.state = {
			username: '',
			password: '',
			invalid: {}, // list of invalid entries on submit
		}
		let headers = localStorage.getItem("headers");
		if(headers) {
			this.props.history.push("/dashboard");
		}
	}



	/**
	 * Validate all input fields before sending a request to the server
	 *
	 */
	validateForm = () => {
		//check if any of the fields are blank
		let invalid = {};

		if(!this.state.username || !this.state.password) {
			invalid.username = true;
		}

		this.setState({invalid});
		if(Object.keys(invalid).length === 0) {
			return true;
		}

		//there are some errors
		console.log("invalid", invalid);
		return false;
	}

	submitForm = async (event) => {
		event.preventDefault();
		this.setState({notification: null});

		//TODO: change validation
		if(!this.validateForm()) {
			//there are some errors, display to the user
			return null;
		}

		//submit login details
		let response = await login(this.state.username, this.state.password);
		if(response.error) {
			return null;
		}
		//push to the dashboard
		//store the user_id and token in localstorage
		let headers = JSON.stringify({
			"user_id": response.user_id,
			"token": response.token,
			"user_type": response.type,
		})
		localStorage.setItem("headers", headers);

		this.props.history.push("/dashboard");

	}
	render() {
		let header = (
			<div className="header">
				Sign In
			</div>
		);

		let notification = null;
		if(this.state.notification) {
			notification = (
				<div id="notification">
					{this.state.notification}
				</div>
			);
		}

		let form = (
			<div className="form">
				{notification}
				<Form onSubmit={this.submitForm}>
					<FormGroup>
						<Label> Email/Phone </Label>
						<Input
							className="userInput"
							type="text"
							name="username"
							value={this.state.username}
							onChange={(event)=>{this.setState({username: event.target.value})}}
						/>
					</FormGroup>
					<FormGroup>
						<Label> Password </Label>
						<Input
							className="userInput"
							type="password"
							name="password"
							value={this.state.password}
							onChange={(event)=> {this.setState({password: event.target.value})}}
							invalid = {this.state.invalid.password}
						/>
						<FormFeedback> {this.state.invalid.password ? this.state.invalid.password_message : null} </FormFeedback>
					</FormGroup>

					<Button
						type="submit"
					>
						Login
					</Button>
				</Form>
			</div>
		);
		return(
			<div className="container">
				{header}
				{form}
			</div>
		);
	}

}
