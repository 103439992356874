import React, {Component} from 'react';

import {
	Container,
	Form,
	Button,
} from 'reactstrap';

import {
	getPatients
} from './api-calls';

export default class Dashboard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			patientList: [],
		}


	}

	async componentDidMount() {
		//fetch the patient list
		let headers = localStorage.getItem("headers");
		if(!headers) {
			this.props.history.push("/");
			return null;
		}
		headers = JSON.parse(headers);
		//TODO: if usertype is carer
		if(headers.user_type == "carer") {
			let patients = await getPatients(headers.user_id, headers.token);
			if(patients.error) {
				//push to login
				this.props.history.push("/");
				return null;
			}
			console.log("pat", patients);
			this.setState({patientList: patients});
		}
	}

	render() {

		let patients = [];
		let patientSection = null;
		if(this.state.patientList.length > 0) {
			for(let i = 0; i < this.state.patientList.length; i++) {
				patients.push(
					<ul>
						<li> {this.state.patientList[i].name}</li>
					</ul>
				);
			}
			patientSection = (
				<div>
					<div className="dashboard-patient-header">
						Patients

					</div>
					<div className="dashboard-patient-list">
						{patients}
					</div>
				</div>
			);
		}



		return (
			<div className="container">
				<div className="dashboard-header">
					Dashboard
				</div>
				{patientSection}
			</div>
		)
	}
}
