import React, {Component} from 'react';

import {
	HashRouter as Router,
	Route,
	Redirect
} from 'react-router-dom';

import Navigation from './navigation';
import Login from './login';
import Dashboard from './dashboard';

export default class App extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Router>
				<div>
					<Navigation>
						<PrivilegedRoute exact path="/" component={Login} redirectTo="/" accessible/>
						<PrivilegedRoute exact path="/dashboard" component={Dashboard} redirectTo="/" accessible/>
					</Navigation>
				</div>
			</Router>
		)
	}
}

class PrivilegedRoute extends Component {

	constructor(props) {
		super(props);

		var props_copy = {};
		Object.keys(props).forEach(function(key) { props_copy[key] = props[key]; });

		props_copy.accessible = undefined;
		props_copy.path = undefined;
		props_copy.redirectTo = undefined;
		props_copy.component = undefined;
		props_copy.exact = undefined;

		if(!('exact' in this.props)) {
			this.exact = false;
		} else {
			this.exact = true;
		}

		this.props_copy = props_copy;
	}

	render() {
		var props = this.props;
		if(this.props.accessible) {
			var props_copy = this.props_copy;

			return (
				<Route path={ props.path } exact={ this.exact } render={(args) => {
					return (
							React.createElement(props.component, Object.assign(props_copy, args))
					);
				}} />
			);
		} else {
			return (
				<Route path={ props.path } exact={ this.exact } render={() => {
					return (
						<Redirect to={props.redirectTo} />
					);
				}} />
			);
		}
	}
}

function isLoggedIn() {
	//check localstorage for user_id, and token
	//if not user_id and token, don't show Sidebar
	let headers = localStorage.getItem("headers");
	if(!headers) {
		return false;
	}
	return true;
}
