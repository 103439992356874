import React, {Component} from 'react';



import {
	Button,
} from 'reactstrap';

import Sidebar from 'react-sidebar';

import './App.css';

import {
	NavLink
} from 'react-router-dom';

const mql = window.matchMedia('(min-width: 800px)');

export default class Navigation extends Component {

	constructor(props) {
		super(props);

		this.state = {
			sidebarOpen: false,
			sidebarDocked: mql.matches,
		};
		mql.addListener(this.mediaQueryChanged);
	}

	mediaQueryChanged = () => {
		this.setState({
			sidebarDocked: mql.matches,
			sidebarOpen: false,

		})
	}

	onSidebarOpen = (open) => {
		this.setState({sidebarOpen: open});
	}

	/**
	 * For now just delete the localStorage items
	 */
	logout = () => {
		localStorage.removeItem("headers");
		//find a way to push to login screen
	}
	render() {
		if(!isLoggedIn()) {
			return this.props.children;
		}
		let sidebar = (
			<div className="sidebar">
				<div className="sidebar-header">
					Side mate
				</div>
				<div className="sidebar-buttons">
				</div>
				<div className="sidebar-logout">
					<Button
						onClick={this.logout}
						color="info"
					>
						Logout
					</Button>
				</div>
			</div>
		)
		return (
			<Sidebar
				sidebar={sidebar}
				open={this.state.sidebarOpen}
				onSetOpen={this.onSidebarOpen}
				docked={this.state.sidebarDocked}
				styles={{sidebar: {background: "grey", minWidth: "150px", maxWidth: "13%"}}}
			>
			{this.props.children}
			</Sidebar>
		)
	}
}

/**
 */
function isLoggedIn() {
	//check localstorage for user_id, and token
	//if not user_id and token, don't show Sidebar
	let headers = localStorage.getItem("headers");
	if(!headers) {
		return false;
	}
	return true;
}
