const DOMAIN_URL = "https://cot.dev.leigoindustries.com.au";

//login
export async function login(username, password) {

	let response = await fetch(DOMAIN_URL + "/users/login", {
		method: "POST",
		body: JSON.stringify({
			identifier: username,
			password: password
		}),
	});

	response = await response.json();
	console.log('res back', response);
	let error = checkError(response);
	console.log('error', error);
	if(!error.error) {
		return response;
	}

	//else there's an error
	return error;

}

/**
 * Return the patient list for this carer.
 * only allow carer to call this function
 */
export async function getPatients(userId, token) {
	console.log("user_id", userId, "token", token);
	let response = await fetch(DOMAIN_URL + "/carers/" + userId + "/patients", {
		method: "GET",
		headers: {
			"User-Id": userId,
			"Authorization": token,
		}
	});

	response = await response.json();
	let error = checkError(response);
	if(!error.error) {
		return response;
	}
	return error;

}

function checkError(response) {
	if(response.error) {
		let error = response.error;
		let resp = {
			"error" : true,
		}

		if(error.name == "identity_already_exists") {
			resp.notification = "The given identifier (email/phone) already exists in our system.";
			resp.name = "contact";
		}
		else if (error.name == "invalid_type") {
			resp.notification = "The given contact information is invalid." //be a bit more thorough
		}


		return resp;
	}

	return false;
}
